import React from 'react'
import * as Yup from 'yup'

import Button from '../Button'
import { Form, Input, Row, Textarea } from '../Form'

import * as s from './style.module.sass'

const formSchema = Yup.object().shape({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  mobilephone: Yup.string(),
  email: Yup.string().email().required(),
  message: Yup.string().required()
})

const FreelanceContactForm = ({ onSubmit }) => (
  <Form schema={formSchema} submitHandler={onSubmit}>
    <input type="hidden" name="type" value="Freelance" />
    <Row>
      <Input
        name="firstname"
        label="Prénom"
        placeholder="Leonard"
      />
    </Row>
    <Row>
      <Input
        name="lastname"
        label="Nom"
        placeholder="De Vinci"
      />
    </Row>
    <Row>
      <Input
        name="mobilephone"
        label="Téléphone"
        placeholder="+33 00 00 00 00"
        visibleValidation
      />
    </Row>
    <Row>
      <Input
        icon="envelope"
        name="email"
        label="Email"
        placeholder="leonard@mail.com"
        visibleValidation
      />
    </Row>
    <Row takeFullHeight>
      <Textarea
        name="message"
        label="Votre message"
        placeholder="Décrivez nous votre besoin."
      />
    </Row>
    <div className={s.buttonContainer}>
      <Button type="full">Envoyer mon message à l'équipe Artelink</Button>
    </div>
  </Form>
)

export default FreelanceContactForm
