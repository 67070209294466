import React from 'react'
import * as s from './row.module.sass'

const Row = ({ children, takeFullHeight = false }) => (
  <div className={[
    s.row,
    takeFullHeight ? s.fullHeight : ''
  ].join(' ')}>
    {children}
  </div>
)

export default Row
