// extracted by mini-css-extract-plugin
export var section = "style-module--section--CjP5u";
export var contact = "style-module--contact--7b+tO";
export var leftPart = "style-module--leftPart--YDyjR";
export var partnersDesktop = "style-module--partnersDesktop--iV-zN";
export var partnersMobile = "style-module--partnersMobile--pcXOo";
export var phoneNumber = "style-module--phoneNumber--SJSPj";
export var additionalInfo = "style-module--additionalInfo--x9XWn";
export var contactForm = "style-module--contactForm--zSm7h";
export var buttonContainer = "style-module--buttonContainer--QfJGL";
export var formContainer = "style-module--formContainer--gsCC9";
export var hide = "style-module--hide--nJ6cn";
export var formSuccess = "style-module--formSuccess---Tv7p";
export var show = "style-module--show--faEpc";