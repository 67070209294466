import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ContentWrapper from '../ContentWrapper'
import Partners from '../Partners'
import AppTabs from '../AppTabs'
import Icon from '../Icon'
import CompanyContactForm from './CompanyContactForm'
import FreelanceContactForm from './FreelanceContactForm'

import * as s from './style.module.sass'

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
        frontmatter {
          text
        }
      }
    }
  `)
  const { text } = data.markdownRemark.frontmatter
  const [formSuccess, setFormSuccess] = useState(false)

  const sendContactForm = (url, form) => {
    const xhr = new XMLHttpRequest()

    const formData = []

    Object.entries(form).forEach(([name, value]) => {
      formData.push({
        name,
        value
      })
    })

    const data = JSON.stringify({ "fields": formData })

    xhr.open('POST', url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.send(data)
  }

  return (
    <div className={s.section}>
      <ContentWrapper className={s.contact}>
        <div className={s.leftPart}>
          <h1>Contact</h1>
          <p>{text}</p>
          {/* <div className={s.phoneNumber}>
            {phoneNumber}
            <div className={s.additionalInfo}>
              (appel non surtaxé)
            </div>
          </div> */}
          <div className={s.partnersDesktop}>
            <Partners inlineMode />
          </div>
        </div>
        <div className={s.contactForm}>
          <div className={[s.formContainer, formSuccess ? s.hide : ''].join(' ')}>
            <AppTabs tabs={[
              {
                title: 'Je suis une entreprise',
                Component: CompanyContactForm,
                componentProps: {
                  onSubmit: (data) => {
                    sendContactForm(
                      'https://api.hsforms.com/submissions/v3/integration/submit/25257386/aba85f76-4cd2-4a89-ab84-7d66d841be51',
                      data
                    )
                    setFormSuccess(true)
                  }
                }
              },
              {
                title: 'Je suis indépendant',
                Component: FreelanceContactForm,
                componentProps: {
                  onSubmit: (data) => {
                    sendContactForm(
                      'https://api.hsforms.com/submissions/v3/integration/submit/25257386/fab10313-599d-4b42-bf2b-66d7f5d39a9e',
                      data
                    )
                    setFormSuccess(true)
                  }
                }
              }]}
            />
          </div>
          <div className={[s.formSuccess, formSuccess ? s.show : ''].join(' ')}>
            <div>
              <Icon icon="valid" iconSize="large" containerSize="large" />
            </div>
            <p>
              <strong>
                Merci.
                <br/>
                Votre message a bien été envoyé à l'équipe d'Artelink.
              </strong>
              <br />
              Nous vous contacterons dès que possible.
            </p>
            <p>
              <strong>A bientôt !</strong>
            </p>
          </div>
          <div className={s.partnersMobile}>
            <Partners inlineMode />
          </div>
        </div>
      </ContentWrapper>
    </div>
  )
}

export default Contact
