/* eslint jsx-a11y/label-has-for: 0 */
import React from 'react'

import { withFormData } from './Form'
import Icon from '../Icon'

import * as s from './textarea.module.sass'

const Textarea = ({ name, label, placeholder, error }) => (
  <div className={[s.container, error ? s.error : ''].join(' ')}>
    <label htmlFor={name}>
      {label}
    </label>
    <textarea
      name={name}
      placeholder={placeholder}
    />
    {
      error && (
        <div className={s.errorMessage}>
          <Icon icon="error" iconSize="xsmall" containerSize="xsmall" />
          <span>{error}</span>
        </div>
      )
    }
  </div>
)

export default withFormData(Textarea)