import React from 'react'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Contact from '../components/Contact'

const ContactPage = () => (
  <Layout>
    <Helmet>
      <title>Contactez notre équipe</title>
      <meta name="description" content="" />
      {/* Open Graph */}
      <meta name="og:title" content="Artelink - Contactez notre équipe" />
      <meta name="og:image" content="" />
      <meta name="og:url" content="" />
      <meta name="og:description" content="" />
    </Helmet>

    <Contact />
  </Layout>
)

export default ContactPage