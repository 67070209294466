import React, { useRef, useState, useEffect } from 'react'
import { Provider, connect } from 'react-redux'
import { createStore } from 'redux'
import * as s from './form.module.sass'

function reducer(state = {
  errors: {},
  touched: []
}, action) {
  switch (action.type) {
    case 'SET_ERRORS':
      return {...state, errors: action.errors}
    case 'TOUCHED_INPUT':
      return {
        ...state,
        // Next line makes sure the array doesn't contain any 
        // duplicate value
        touched: [...new Set([...state.touched, action.input])]
      }
    default:
      return {...state}
  }
}

function generateErrorsObject(errors, touched, hasSubmitted) {
  const obj = {}
  for (let err of errors) {
    if (hasSubmitted || touched.indexOf(err.path) !== -1) {
      obj[err.path] = err.message
    }
  }
  return obj
}

const Form = ({ children, schema, submitHandler }) => {
  const formRef = useRef(null)
  const [store] = useState(createStore(reducer))
  const [touched, setTouched] = useState([])
  const [hasSubmitted, setHasSubmitted] = useState(false)

  function formValues() {
    const formValues = {}
    for (let pair of new FormData(formRef.current).entries()) {
      formValues[pair[0]] = pair[1]
    }
    return formValues
  }

  function onSubmit(e) {
    e.preventDefault()
    setHasSubmitted(true)
    beforeSubmit(true)
  }

  function beforeSubmit(submit = false) {
    try {
      schema.validateSync(formValues(), { abortEarly: false })
      store.dispatch({
        type: 'SET_ERRORS',
        errors: []
      })
      if (submit) {
        const data = formValues()
        submitHandler(data)
      }
    } catch (errors) {
      schema.validate(formValues(), { abortEarly: false }).catch((errors) => {
        store.dispatch({
          type: 'SET_ERRORS',
          errors: generateErrorsObject(errors.inner, touched, hasSubmitted)
        })
      })
    }
  }

  // When input is touched, save it in store and local state
  function saveTouchedInput(input) {
    store.dispatch({
      type: 'TOUCHED_INPUT', input
    })
    setTouched([...new Set([...touched, input])])
  }

  function onBlurCapture(e) {
    saveTouchedInput(e.target.name)
    beforeSubmit()
  }

  // Validate at every render
  useEffect(beforeSubmit)

  return (
    <Provider store={store}>
      <form
        ref={formRef}
        className={s.form}
        onSubmit={onSubmit}
        onBlurCapture={onBlurCapture}
      >
        {children}
      </form>
    </Provider>
  )
}

export default Form

export function withFormData(Component) {
  return connect((state, ownProps) => ({
    error: state.errors[ownProps.name],
    touched: state.touched.indexOf(ownProps.name) !== -1
  }))(Component)
}