/* eslint jsx-a11y/label-has-for: 0 */
import React, { useState, useRef } from 'react'
import Icon from '../Icon'
import { withFormData } from './Form'
import * as s from './input.module.sass'

const Input = ({
  name,
  label,
  placeholder,
  icon = null,
  error,
  touched,
  visibleValidation = false
}) => {
  const inputRef = useRef(null)
  const [value, setValue] = useState('')
  const showValidation = visibleValidation && touched && !error && value.length > 0

  return (
    <div className={`
        ${s.container}
        ${error ? s.error : ''}
        ${showValidation ? s.valid : ''}
        ${icon !== null ? s.withIcon: ''}
      `}
    >
      <label htmlFor={name}>{label}</label>
      <input
        ref={inputRef}
        onBlur={(e) => setValue(e.target.value)}
        type="text"
        name={name}
        placeholder={placeholder}
      />
      {
        icon && (
          <div className={s.leftIcon}>
            <Icon icon={icon} iconSize="xsmall" containerSize="small" />
          </div>
        )
      }
      {
        error && (
          <>
            <div className={s.errorMessage}>{error}</div>
            <div className={s.rightIcon}>
              <Icon icon="error" iconSize="xsmall" containerSize="small" />
            </div>
          </>
        )
      }
      {
        showValidation && (
          <div className={s.rightIcon}>
            <Icon icon="valid" iconSize="xsmall" containerSize="small" />
          </div>
        )
      }
    </div>
  )
}

export default withFormData(Input)